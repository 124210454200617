<template>
  <div class="pt-3">
    <Loader v-if="!activeEmployees || activeEmployees.length == 0" />
    <div class="dashboard__container--header" >
      <div class="text-left mb-3" style="width:100%;">
              
       <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isActive, 'btn__dark btn__small': !isActive }" @click="showActive()">Active Employees</button>
        <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isOnboarding, 'btn__dark btn__small': !isOnboarding }" @click="showOnboarding()">Onboarding Employees</button>
        <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isTerminated, 'btn__dark btn__small': !isTerminated }" @click="showTerminated()">Terminated Employees</button>
        <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isHiredDirect, 'btn__dark btn__small': !isHiredDirect }" @click="showHiredDirect()">Hired Direct</button>

      </div>
    </div>
    <div class="dashboard__container--body">
      <UserTable :groupUsers="sortedActive" v-if="isActive && activeEmployees && activeEmployees.length >= 1" />
      <UserTable :groupUsers="onboardingEmployees" v-if="isOnboarding && onboardingEmployees && onboardingEmployees.length >= 1" />
      <UserTable :groupUsers="sortedTerminated" v-if="isTerminated && terminatedEmployees && terminatedEmployees.length >= 1" />
      <UserTable :groupUsers="hiredDirectEmployees" v-if="isHiredDirect && hiredDirectEmployees && hiredDirectEmployees.length >= 1" />
    </div>
  </div>
</template>




<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'

export default {
  name: 'onboardingEmployees',
  data() {
    return {
      isActive: true,
      isOnboarding: false,
      isTerminated: false,
      isHiredDirect: false
    }
  },
  created () {
    this.$store.dispatch("getEmployeeReportData") 
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'onboardingEmployees', 'activeEmployees', 'terminatedEmployees', 'hiredDirectEmployees']),
    sortedTerminated() {
      // Use the spread operator to create a new array and then sort it by jumpTerminationDate
      return [...this.terminatedEmployees].sort((a, b) => {
        // Assuming jumpTerminationDate is in ISO date string format
        const dateA = new Date(a.jumpTerminationDate);
        const dateB = new Date(b.jumpTerminationDate);

        // Compare the dates
        return dateB - dateA;
      });
    },
    sortedActive() {
      // Use the spread operator to create a new array and then sort it by jumpTerminationDate
      return [...this.activeEmployees].sort((a, b) => {
        // Assuming jumpTerminationDate is in ISO date string format
        const dateA = new Date(a.jumpHireDate);
        const dateB = new Date(b.jumpHireDate);

        // Compare the dates
        return dateB - dateA;
      });
    },
  },
  components: {
    Loader,
    UserTable
  },
  methods: {
    showActive() {
      this.isActive = true
      this.isOnboarding = false
      this.isTerminated = false
      this.isHiredDirect = false
    },
    showOnboarding() {
      this.isActive = false
      this.isOnboarding = true
      this.isTerminated = false
      this.isHiredDirect = false
    },
    showTerminated() {
      this.isActive = false
      this.isOnboarding = false
      this.isTerminated = true
      this.isHiredDirect = false
    },
    showHiredDirect() {
      this.isActive = false
      this.isOnboarding = false
      this.isTerminated = false
      this.isHiredDirect = true
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearEmployeeReportData") 
  }
}
</script>